  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: #F2F2F2;
    height: 100%;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  a {
    text-decoration: none !important;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  /* [type=button]{
    -webkit-appearance: none !important;
  } */

  .pac-container {
    z-index: 10000!important;
  }


  textarea {
    resize: none;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #2c2c2c;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

